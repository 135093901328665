import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as SendLogo } from "../../Assets/paper-plane-top-solid.svg";
import BotActionInput from "../../Components/BotActionInput/BotActionInput";
import ChatMessage from "../../Components/ChatMessage/ChatMessage";
import ChatMessageComposer from "../../Components/ChatMessageComposer/ChatMessageComposer";
import Communicator from "../../Helper/Communicator";
import { useConfigStore } from "../../Stores/Config";
import { useRouterStore } from "../../Stores/Router";
import { useSessionStore } from "../../Stores/Session";
import { ChannelTypes, useTicketStore } from "../../Stores/Ticket";
import "./Conversation.scss";

const actionsWithoutKai = ["buttons", "chatinput"];

function Conversation() {
  const routerStore = useRouterStore();
  const feedbackItemStore = useTicketStore();
  const sessionStore = useSessionStore();
  const ticketStore = useTicketStore();
  const configStore = useConfigStore();
  const [composerHeight, setComposerHeight] = useState(57);
  const bottomRef = useRef(null as any);
  const currentTicket = ticketStore.currentTicket;

  const isLoading =
    feedbackItemStore.loadingComments ||
    !feedbackItemStore.currentTicket ||
    (currentTicket?.bot?.active &&
      (feedbackItemStore?.currentComments ?? []).length === 0) ||
    (!ticketStore.currentTicket?.conversationClosed &&
      currentTicket?.bot?.active &&
      !feedbackItemStore.currentBotAction?.action);

  const getComposerType = () => {
    if (isLoading) {
      return "none";
    }

    if (feedbackItemStore.currentTicket?.bot?.active) {
      const isAnswerBotActive =
        feedbackItemStore.currentBotAction?.action?.type === "answerbotflow" ||
        feedbackItemStore.currentBotAction?.action?.type === "chatinput";
      if (isAnswerBotActive || !feedbackItemStore.currentBotAction?.action) {
        return "botTextInput";
      } else {
        return "botInput";
      }
    }

    return "textInput";
  };
  const composerType = getComposerType();
  const showTypingIndicator = ticketStore.typingUser || isLoading;
  const botWrittingText = ticketStore.botWrittingText;

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const articleDivs = document.querySelectorAll(
      ".helpcenter-conversation-article"
    );

    const handleClick = (event: any) => {
      const articleUrl = event.currentTarget.getAttribute("data-url");
      if (articleUrl) {
        Communicator.openURL(articleUrl, true);
      }
    };

    articleDivs.forEach((articleDiv) => {
      articleDiv.addEventListener("click", handleClick);
    });

    return () => {
      articleDivs.forEach((articleDiv) => {
        articleDiv.removeEventListener("click", handleClick);
      });
    };
  }, [feedbackItemStore.currentComments]);

  useEffect(() => {
    if (routerStore.currentData && routerStore.currentData.shareToken) {
      feedbackItemStore.loadCurrentTicket(routerStore.currentData.shareToken);
    }
  }, [routerStore.currentData, ticketStore.previousTickets]);

  useEffect(() => {
    return () => {
      feedbackItemStore.clearCurrentTicket();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [
    feedbackItemStore.currentComments,
    feedbackItemStore.currentBotAction,
    ticketStore.currentTicket?.conversationClosed,
    ticketStore.currentTicket?.type,
    ticketStore?.botWrittingText,
  ]);

  const renderMessageComposer = () => {
    if (composerType !== "textInput") {
      return;
    }

    const currentTicket = ticketStore.currentTicket;

    if (currentTicket?.conversationClosed) {
      return null;
    }

    if (
      currentTicket?.channel?.type === ChannelTypes.FACEBOOK ||
      currentTicket?.channel?.type === ChannelTypes.INSTAGRAM ||
      currentTicket?.channel?.type === ChannelTypes.WHATSAPP
    ) {
      return null;
    }

    return (
      <ChatMessageComposer
        typingStatusChanged={(isTyping) => {
          ticketStore.isTyping(isTyping);
        }}
        onHeightUpdated={(height) => {
          setComposerHeight(height);
        }}
        sendMessage={(message, attachments) => {
          feedbackItemStore.sendComment(message, attachments);
        }}
      />
    );
  };

  const renderAnswerBotMessageComposer = () => {
    if (composerType !== "botTextInput") {
      return null;
    }

    if (ticketStore.currentTicket?.conversationClosed) {
      return null;
    }

    const botIsWriting = botWrittingText && botWrittingText.length > 0;
    const botIsTyping = showTypingIndicator;

    return (
      <ChatMessageComposer
        answerBotIsActive
        onHeightUpdated={(height) => {
          setComposerHeight(height);
        }}
        disabled={botIsWriting || botIsTyping}
        sendMessage={(message, files) => {
          if (
            feedbackItemStore.currentBotAction?.action?.type === "chatinput"
          ) {
            ticketStore.answerBotAction(
              {
                text: message,
                message: message,
              },
              files
            );
          } else {
            ticketStore.answerBotAction(
              {
                question: message,
                message: message,
                aiTools: configStore.aiTools,
                isFirstMessage: !feedbackItemStore.hasAnswerBotReply(),
              },
              files
            );
          }
        }}
      />
    );
  };

  const renderConversationClosed = () => {
    if (!ticketStore.currentTicket?.conversationClosed) {
      return null;
    }

    return (
      <div className="conversation-ended">
        <div className="conversation-ended-label">
          {configStore.translateText("conversationEnded")}
        </div>
        {configStore.config.hideAllConversations ? null : (
          <div
            className="action-button form-send-button"
            onClick={() => {
              ticketStore.createNewConversation({
                isManualStart: true,
              });
            }}
          >
            {configStore.translateText("sendMessage")}
            <SendLogo />
          </div>
        )}
      </div>
    );
  };

  const renderDisabledTicket = () => {
    const currentTicket = ticketStore.currentTicket;

    if (
      currentTicket?.channel?.type !== ChannelTypes.FACEBOOK &&
      currentTicket?.channel?.type !== ChannelTypes.INSTAGRAM &&
      currentTicket?.channel?.type !== ChannelTypes.WHATSAPP
    ) {
      return null;
    }

    const getChannelName = () => {
      switch (currentTicket?.channel?.type) {
        case ChannelTypes.FACEBOOK:
          return "Facebook";

        case ChannelTypes.INSTAGRAM:
          return "Instagram";

        case ChannelTypes.WHATSAPP:
          return "WhatsApp";
        default:
          return "Channel";
      }
    };

    const label = configStore
      .translateText("wrongConversationChannel")
      .replace("{channel}", getChannelName());

    return (
      <div className="conversation-ended">
        <div className="conversation-ended-label">{label}</div>
        {configStore.config.hideAllConversations ? null : (
          <div
            className="action-button form-send-button"
            onClick={() => {
              ticketStore.createNewConversation({
                isManualStart: true,
              });
            }}
          >
            {configStore.translateText("sendMessage")}
            <SendLogo />
          </div>
        )}
      </div>
    );
  };

  var bottomToolbarHeight =
    composerType === "textInput" || composerType === "botTextInput"
      ? composerHeight
      : 0;
  if (ticketStore.currentTicket?.conversationClosed) {
    bottomToolbarHeight = 140;
  }

  return (
    <div
      className={`conversation ${
        feedbackItemStore?.currentTicket?.type !== "INQUIRY" &&
        feedbackItemStore?.currentTicket?.type !== "BOT"
          ? "conversation--ticket"
          : ""
      }`}
    >
      <div
        className="conversation-messages"
        style={{
          height: `100%`,
          paddingBottom: `${bottomToolbarHeight + 35}px`,
        }}
      >
        {!feedbackItemStore?.currentTicket?.hideContent && (
          <ChatMessage
            key="initial"
            chatMessage={{
              type: "FEEDBACK_ITEM",
              data: feedbackItemStore.currentTicket,
              createdAt: feedbackItemStore.currentTicket?.createdAt,
            }}
            isCreator={true}
            showTime={true}
            lastOfGroup={true}
          />
        )}
        {feedbackItemStore.currentComments &&
          feedbackItemStore.currentComments
            .filter(
              (comment) =>
                !(comment.type === "BOT" && comment.data?.action) &&
                !comment.data?.hiddenAction
            )
            .map((comment, i, currentComments) => {
              var nextComment = null;
              var isLastInSection = false;
              var showTime = true;
              if (i < currentComments.length) {
                nextComment = currentComments[i + 1];
              }

              if (!nextComment) {
                isLastInSection = true;
              }

              if (comment.session && nextComment?.session === comment.session) {
                isLastInSection = true;
              }

              if (comment.user && nextComment?.user === comment.user) {
                isLastInSection = true;
              }

              if (comment.type !== nextComment?.type) {
                isLastInSection = true;
              }

              if (
                comment.type === "BOT" &&
                nextComment?.type === "BOT_INPUT_REPLY"
              ) {
                isLastInSection = false;
              }

              if (
                comment.type === "BOT_INPUT_REPLY" &&
                nextComment?.type === "BOT_INPUT_REPLY"
              ) {
                isLastInSection = false;
              }

              const activeActionType =
                ticketStore.currentBotAction?.action?.type;
              if (
                comment.type === "BOT" &&
                !nextComment &&
                activeActionType &&
                !actionsWithoutKai.includes(activeActionType)
              ) {
                isLastInSection = false;
                showTime = false;
              }

              if (nextComment && !isLastInSection) {
                var duration = moment.duration(
                  moment(nextComment.createdAt).diff(comment.createdAt)
                );
                var minutes = duration.asMinutes();
                if (minutes < 30) {
                  showTime = false;
                }
              }

              var isCreator = false;
              if (comment.session?.gleapId === sessionStore?.session?.gleapId) {
                isCreator = true;
              }

              return (
                <ChatMessage
                  key={comment.id}
                  chatMessage={comment}
                  showTime={showTime}
                  isCreator={isCreator}
                  lastOfGroup={isLastInSection}
                  imageLoaded={scrollToBottom}
                />
              );
            })}
        {(composerType === "botInput" || composerType === "botTextInput") && (
          <BotActionInput key="bot-action-input" />
        )}
        {showTypingIndicator && !botWrittingText && (
          <ChatMessage
            key="typing"
            lastOfGroup={true}
            chatMessage={{
              data: {
                content: "Typing…",
              },
              user: ticketStore.typingUser,
              type: "TEXT",
            }}
            forceTyping={true}
            showTime={false}
          />
        )}
        {botWrittingText && botWrittingText.length > 0 && (
          <ChatMessage
            key="botwritting"
            lastOfGroup={true}
            chatMessage={{
              data: {
                answerBot: true,
                hasAnswer: true,
                isWritting: true,
                sources: [],
                content: botWrittingText,
              },
              user: undefined,
              session: undefined,
              type: "BOT",
            }}
            showTime={false}
          />
        )}
        <div ref={bottomRef} />
      </div>
      {renderAnswerBotMessageComposer()}
      {renderMessageComposer()}
      {renderConversationClosed()}
      {renderDisabledTicket()}
    </div>
  );
}

export default Conversation;
