import { Node } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    youtubePlaceholder: {
      setYouTubePlaceholder: () => ReturnType;
    };
  }
}

export const YouTubePlaceholderExtension = Node.create({
  name: 'youtubePlaceholder',

  group: 'block',

  atom: true,

  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'div[data-type="youtube-placeholder"]',
      },
    ];
  },

  renderHTML() {
    return ['div', { 'data-type': 'youtube-placeholder' }];
  },

  addCommands() {
    return {
      setYouTubePlaceholder:
        () =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
          }),
    };
  },
});

export default YouTubePlaceholderExtension;
