import { mergeAttributes, Node } from '@tiptap/core'
import { DOMOutputSpec } from '@tiptap/pm/model'

export interface ButtonOptions {
  HTMLAttributes: {
    [key: string]: any
  }
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    button: {
      setButton: (options: { href: string, label?: string }) => ReturnType
    }
  }
}

export const ButtonExtension = Node.create<ButtonOptions>({
  name: 'editor-button',
  group: 'block',
  content: 'inline*',
  selectable: true,
  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'div.editor-button',
        getAttrs: dom => dom instanceof HTMLElement ? {
          type: "editor-button"
        } : {}
      },
    ];
  },

  renderHTML({ HTMLAttributes }): DOMOutputSpec {
    return [
      'div',
      mergeAttributes({
        ...HTMLAttributes,
        class: `editor-button`,
      }),
      0
    ];
  },

  addCommands() {
    return {
      setButton:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          })
        },
    }
  },
})
