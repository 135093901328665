import { Node } from '@tiptap/core';

export const HelpcenterLinkExtension = Node.create({
  name: 'helpCenterArticle',
  group: 'block',
  atom: true,

  renderHTML({ HTMLAttributes }) {
    const { articleTitle, articleDescription, articleUrl } = HTMLAttributes;

    const html = `<div class="helpcenter-conversation-article"><div class="article-header-container"><a href="${articleUrl}"><div class="article-header">${articleTitle}</div></a></div><div class="article-description">${articleDescription}</div></div>`;

    return ['helpcenterarticle', HTMLAttributes, html];
  },

  addAttributes() {
    return {
      articleId: {
        default: null,
      },
      articleTitle: {
        default: 'No title',
      },
      articleDescription: {
        default: 'No description',
      },
      articleUrl: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'helpcenterarticle',
        getAttrs: (node) => {
          if (typeof node === 'string') {
            return {};
          }

          if (node.nodeType !== 1 /* Node.ELEMENT_NODE */) {
            return {};
          }

          const element = node as HTMLElement;
          return {
            articleId: element.getAttribute('articleId') || null,
            articleTitle: element.getAttribute('articleTitle') || 'No title',
            articleDescription: element.getAttribute('articleDescription') || 'No description',
            articleUrl: element.getAttribute('articleUrl') || null,
          };
        },
      },
    ];
  },

  renderText({ node }) {
    return `${node?.attrs?.articleTitle}\n${node?.attrs?.articleUrl}\n`;
  },
});
