import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import Youtube from '@tiptap/extension-youtube';
import {
  BlockquoteFigure,
  CodeBlock,
  Color,
  Column,
  Columns,
  Details,
  DetailsContent,
  DetailsSummary,
  Document,
  Dropcursor,
  Emoji,
  Figcaption,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  ImageBlock,
  Link,
  Placeholder,
  StarterKit,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TaskItem,
  TaskList,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  UniqueID,
} from '.';
import { ButtonExtension } from './custom/Button/ButtonExtension';
import { Callout } from './custom/Callout/TipTapCallout';
import { HelpcenterLinkExtension } from './custom/HelpCenterLink/HelpCenterLinkExtension';
import iFrame from './custom/iFrame/iFrame';
import YouTubePlaceholderExtension from './custom/YouTube/YouTubePlaceholderExtension';

interface ExtensionKitProps {}

export const ExtensionKit = ({}: ExtensionKitProps) => [
  Document,
  Columns,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Column,
  // Selection,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HorizontalRule,
  UniqueID.configure({
    types: ['paragraph', 'heading', 'blockquote', 'codeBlock', 'table'],
  }),
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    blockquote: {},
    history: false,
    codeBlock: false,
  }),
  Details.configure({
    persist: true,
    HTMLAttributes: {
      class: 'details',
    },
  }),
  Emoji,
  DetailsContent,
  DetailsSummary,
  CodeBlock,
  TextStyle,
  FontSize,
  FontFamily,
  Color,
  TrailingNode,
  Link.configure({
    openOnClick: false,
    protocols: ['http', 'https', 'mailto', 'tel', 'gleap'],
  }),
  Highlight.configure({ multicolor: true }),
  Underline,
  Image,
  ImageBlock,
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ['heading', 'paragraph'],
  }),
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: () => '',
  }),
  Focus,
  Figcaption,
  BlockquoteFigure,
  Dropcursor.configure({
    width: 2,
    class: 'ProseMirror-dropcursor border-black',
  }),
  Callout,
  YouTubePlaceholderExtension,
  iFrame,
  ButtonExtension,
  Youtube.configure({ HTMLAttributes: { class: 'youtube-video' } }),
  HelpcenterLinkExtension,
  Mention,
  History,
];

export default ExtensionKit;
