import { ReactComponent as ArrowRight } from '../../Assets/angle-right-solid.svg';
import { Checklist } from '../../Stores/Checklist';
import { useConfigStore } from '../../Stores/Config';
import { useRouterStore } from "../../Stores/Router";
import "./ChecklistCard.scss";

function ChecklistCard({ checklist }: { checklist: Checklist }) {
  const routerStore = useRouterStore();
  const configStore = useConfigStore();

  const checklistSteps = checklist?.outbound?.config?.steps ?? [];
  let currentStep = checklist.completedSteps? checklist?.outbound?.config?.steps.find(step => !checklist.completedSteps?.includes(step?.id)) : checklistSteps[0];
  if (!currentStep) {
    currentStep = checklistSteps[0];
  }

  let duration = 0;
  checklistSteps.forEach((step) => {
    if (step.duration && step.duration > 0) {
      duration += step.duration;
    }
  });

  if (!checklist) {
    return null;
  }

  var progress = (checklist?.completedSteps?.length || 0) / checklistSteps.length * 100;
  if (progress < 100) {
    progress += 3;
  }

  return (
    <div
      className="checklist-card"
      onClick={() => {
        routerStore.setPage("checklistdetails", { id: checklist.id });
      }}
    >
      <div className="checklist-card-content">
        <div className="checklist-card-content-content">
          <div className="checklist-card-content-title">{checklist?.outbound?.subject}</div>
          <ArrowRight className='checklist-card-content-show-details' />
        </div>
        <div className="checklist-progress-labels">
          <div className="checklist-progress-label">{configStore.translateTextWithVars("taskProgress", {
            a: checklist?.completedSteps?.length ?? 0,
            b: checklistSteps.length
          })}</div>
        </div>
        <div className="checklist-progress-bar">
          <div className="checklist-progress-bar-progress" style={{
            width: `${progress}%`
          }} />
        </div>
        <div className='checklist-nextstep'>
          <b>{configStore.translateText("nextStep")}:</b> {currentStep?.title ?? ""}
        </div>
      </div>
    </div>
  );
}

export default ChecklistCard;
