import { Image } from '../Image';

export const ImageBlock = Image.extend({
  name: 'imageBlock',

  group: 'block',

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: '',
        parseHTML: (element) => element.getAttribute('src'),
        renderHTML: (attributes) => ({
          src: attributes.src,
        }),
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      align: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-align'),
        renderHTML: (attributes) => ({
          'data-align': attributes.align,
        }),
      },
      alt: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('alt'),
        renderHTML: (attributes) => ({
          alt: attributes.alt,
        }),
      },
      link: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-link'),
        renderHTML: (attributes) => ({
          'data-link': attributes.link,
        }),
      },
      target: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-target'),
        renderHTML: (attributes) => ({
          'data-target': attributes.target,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src*="tiptap.dev"]:not([src^="data:"]), img[src*="windows.net"]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const hasLink = HTMLAttributes["data-link"];
    const alignOptions = {
      center: "center",
      left: "flex-start",
      right: "flex-end",
    };
    const align = alignOptions[HTMLAttributes["data-align"] as keyof typeof alignOptions] ?? "center";

    if (hasLink) {
      return [
        "a",
        {
          href: HTMLAttributes["data-link"],
          target: HTMLAttributes["data-target"],
          style: {
            display: "flex",
            justifyContent: align ?? "center",
            width: "100%",
          },
        },
        [
          "img",
          {
            ...HTMLAttributes,
            style: {
              width: HTMLAttributes["data-width"] ?? "100%",
            },
          },
        ],
      ];
    } else {
      return [
        "img",
        {
          ...HTMLAttributes,
          style: {
            width: HTMLAttributes["data-width"] ?? "100%",
            alignSelf: align ?? "center",
          },
        },
      ];
    }
  },
});

export default ImageBlock;
