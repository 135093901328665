import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import Communicator from '../../Helper/Communicator';
import { ConfigMenuItem } from '../../Stores/Config';
import { useRouterStore } from '../../Stores/Router';
import { useTicketStore } from '../../Stores/Ticket';
import "./HomeButtonOptionsButton.scss";

function HomeButtonOptionsButton({ menuItem, index }: {
    menuItem: ConfigMenuItem;
    index: number;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const routerStore = useRouterStore();
    const ticketStore = useTicketStore();

    return (
        <div id={`menu-item-${index}`} className="home-button" onClick={() => {
            if (isLoading) {
                return;
            }

            // Make component loading
            setIsLoading(true);

            if (menuItem.actionType === "CUSTOM_ACTION") {
                if (menuItem.actionBody) {
                    Communicator.runCustomAction(menuItem.actionBody);
                    Communicator.closeWidget();
                }
            } else if (menuItem.actionType === "REDIRECT_URL") {
                if (menuItem.actionBody) {
                    Communicator.openURL(menuItem.actionBody, menuItem.actionOpenInNewTab ? true : false);
                    Communicator.closeWidget();
                }
            } else if (menuItem.actionType === "BOT") {
                ticketStore.createNewConversation({
                    botId: menuItem.botId,
                    isManualStart: true,
                });
            } else {
                routerStore.pushPage("flow", {
                    flowId: menuItem.actionType,
                });
            }
        }}>
            <div className="home-button-icon">
                {(menuItem.icon && menuItem.icon.endsWith(".svg")) ? <ReactSVG src={menuItem.icon} /> : <img src={menuItem.icon} alt={menuItem.title} />}
            </div>
            <div className='home-button-title'>{menuItem.title}</div>
        </div>
    );
}

export default HomeButtonOptionsButton;
