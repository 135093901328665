import "core-js/es/array/flat";
import "core-js/es/object/entries";
import "core-js/es/object/from-entries";
import "core-js/es/object/values";
import "moment/min/locales";
import ReactDOM from "react-dom/client";
import CommunicationManager from "./Helper/CommunicationManager";
import Communicator from "./Helper/Communicator";
import HeightUpdateManager from "./Helper/HeightUpdateManager";
import "./index.scss";
import { useChecklistStore } from "./Stores/Checklist";
import { useFormStore } from "./Stores/Form";
import { useHelpCenterStore } from "./Stores/HelpCenter";
import { useRouterStore } from "./Stores/Router";
import { useTicketStore } from "./Stores/Ticket";
import App from "./Views/App/App";

export const handleGleapLink = (href: string, target: boolean) => {
  // Check if element.href starts with gleap:
  if (href.startsWith("gleap:")) {
    try {
      const urlParts = href.split("/");
      const type = urlParts[2];

      if (type === "flow") {
        const identifier = urlParts[3];
        useFormStore.setState({
          feedbackFlow: identifier,
          isSurveyFlow: false,
        });
        useRouterStore.getState().pushPage("flow", {
          flowId: identifier,
        });

        return false;
      }

      if (type === "bot") {
        const identifier = urlParts[3];
        useTicketStore.getState().createNewConversation({
          botId: identifier,
        });
        return false;
      }

      if (type === "article") {
        const identifier = urlParts[3];
        useHelpCenterStore.getState().openArticle(identifier, true);

        return false;
      }

      if (type === "action") {
        const identifier = urlParts[3];

        Communicator.runCustomAction(
          identifier,
          useTicketStore.getState()?.currentTicket?.shareToken
        );

        if (true) {
          Communicator.closeWidget();
        }

        return false;
      }

      if (type === "collection") {
        const identifier = urlParts[3];
        useHelpCenterStore.getState().openCollection(identifier, true);
        return false;
      }

      if (type === "checklist") {
        const identifier = urlParts[3];
        useRouterStore.getState().pushPage("checklists");
        useChecklistStore.getState().startChecklist(identifier);

        return false;
      }

      if (type === "survey") {
        const identifier = urlParts[3];
        useFormStore.setState({
          feedbackFlow: identifier,
          isSurveyFlow: true,
        });
        useRouterStore.getState().pushPage("flow", {
          flowId: identifier,
        });

        return false;
      }

      if (type === "tour") {
        const identifier = urlParts[3];
        useRouterStore.getState().pushPage("checklists");
        useChecklistStore.getState().startChecklist(identifier);

        return false;
      }

      if (type === "news") {
        const identifier = urlParts[3];
        useRouterStore.getState().pushPage("newsdetails", {
          id: identifier,
        });
        return false;
      }
    } catch (e) {
      console.error("Failed to handle Gleap link: ", href);
    }
  }

  Communicator.openURL(href, target);
  return false;
};

var communicationInitialized = false;
const initializeCommunication = () => {
  if (communicationInitialized) {
    return;
  }

  communicationInitialized = true;
  HeightUpdateManager.getInstance().start();
  CommunicationManager.getInstance().sendMessage({
    name: "ping",
  });
};
initializeCommunication();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

document.onclick = function (e) {
  e = e || window.event;
  var element: any = e.target || e.srcElement;

  // Traverse up the DOM tree to find the <a> tag
  while (element && element.tagName !== "A" && element.parentNode) {
    element = element?.parentNode;
  }

  if (!element || element.tagName !== "A") {
    return;
  }

  if (
    element &&
    element.tagName === "A" &&
    element.href &&
    element.href.length > 0
  ) {
    if (/^(https?:|mailto:|gleap:|tel:|phone:)/i.test(element.href)) {
      return handleGleapLink(
        element.href,
        element.getAttribute("target") === "_blank"
      );
    }
  }
};
